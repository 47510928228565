



// waves
import wave from './img/wave.svg';
import wave_reverse from './img/wave-reverse.svg';
import wave_yellow from './img/wave-yellow.svg';
import wave_yellow_reverse from './img/wave-reverse-yellow.svg';
import public_pic from './img/Profil_pic_BG.webp';
import wave_grey_reverse from './img/wave-reverse-grey.svg'
import wave_grey from './img/wave_grey.svg'

// Front-End ikonok
import htmlIcon from './icons/skills_icon/html.svg';
import cssIcon from './icons/skills_icon/css-3.svg';
import jsIcon from './icons/skills_icon/javascript.svg';
import sassIcon from './icons/skills_icon/sass.svg';
import reactIcon from './icons/skills_icon/react.svg';
import bootstrapIcon from './icons/skills_icon/bootstrap-4.svg';
import angular from './icons/skills_icon/angular.svg';

// Back-End ikonok
import nodejsIcon from './icons/skills_icon/node-js.png';
import pythonIcon from './icons/skills_icon/python.svg';
import expressIcon from './icons/skills_icon/express.svg';
import djangoIcon from './icons/skills_icon/django.png';

// Adatbázis ikonok
import mssqlIcon from './icons/skills_icon/sqlserver.svg';
import mysqlIcon from './icons/skills_icon/mysql.png';
import mongodbIcon from './icons/skills_icon/mongodb.svg';

// Egyéb ikonok
import gitIcon from './icons/skills_icon/git.svg';
import githubIcon from './icons/skills_icon/github.svg';
import figmaIcon from './icons/skills_icon/figma.svg';
import vscodeIcon from './icons/skills_icon/visual-studio-code.svg';
import linuxIcon from './icons/skills_icon/linux.svg';

//CV
import CV from "../assets/download/CV_2023hu.pdf"

export const pdf = {
    CV
}



export const img = {
    wave,
    wave_reverse,
    wave_yellow,
    wave_yellow_reverse,
    public_pic,
    wave_grey_reverse,
    wave_grey,
}

export const icons = {
    htmlIcon,
    cssIcon,
    jsIcon,
    sassIcon,
    reactIcon,
    bootstrapIcon,
    nodejsIcon,
    pythonIcon,
    expressIcon,
    djangoIcon,
    mssqlIcon,
    mysqlIcon,
    mongodbIcon,
    gitIcon,
    githubIcon,
    figmaIcon,
    vscodeIcon,
    linuxIcon,
    angular
}

export const categoryMap = {
    Webpage: {
      en: 'Website',
      hu: 'Weboldal', 
    },
    Backend: {
      en: 'Back-end',
      hu: 'Back-end',
    },
    Data: {
      en: 'Database',
      hu: 'Adatbázis',
    },
  };